export const ContactInfo = {
  EMAIL: 'support@choiee.com',
  ZALO: '0932059267',
};

export const NotFoundImage = '/images/image-not-found.webp';

export const LocalStorageKey = {
  PERMISSION: 'PERMISSION',
  SUBSCRIBED: 'SUBSCRIBED',
};
export type LocalStorageKeyType = keyof typeof LocalStorageKey;
