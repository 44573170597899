export enum AppRoute {
  HOME = '/',
  REGISTER_BETA_CUSTOMER = '/register-beta-customer',
  SUGGEST_PLAYGROUND = '/suggest-playground',
  ABOUT = '/about',
  CONTACT = '/contact',
  PRIVACY = '/privacy',
  LICENSE = '/license',
  LOGIN = '/login',
  REGISTER = '/register',
  FORGET_PASSWORD = '/forget-password',
  FIND = '/choiee/find',
  BOOKING = '/choiee/booking',
  BLOGS = '/blogs',
  GAMES = '/choiee/games',
  EXPLORE = '/choiee/explore',
  MY_PROFILE = '/choiee/my-profile',
  CREATE_GAME = '/choiee/create-game',
  FAVORITES = '/choiee/favorites',
  VERIFY = '/verify',
  CREATE_USERNAME = '/create-username',
  SETTINGS = '/choiee/settings',
  SETTING_MY_PROFILE = '/choiee/settings/profile',
  GAME_MANAGER = '/choiee/game-manager',
  GAME = '/choiee/game',
  NOTIFICATION = '/choiee/notification',
}

export interface INavRoute {
  name: string;
  href: AppRoute;
}
